@import 'vars'

#portfolio
  > .content
    width: 100%

    @media (max-width: $md)
      position: relative
      padding: 0 $mobile-wrapper-padding

  &.board-opened
    .projects-board__preview
      height: unset

    .projects-board__container
      max-height: 80%
      height: unset

.projects-board__container
  position: relative
  overflow: auto
  height: 50%

  .swiper
    height: 100%

.portfolio__heading
  display: flex
  justify-content: space-between
  align-items: center

  @media (max-width: $md)
    margin-bottom: 5rem

  @media (max-width: $sm)
    margin-bottom: 2rem

  .page-header
    @media (max-width: $md)
      margin: 0

.projects-board
  display: flex
  flex-direction: column
  gap: 30px
  height: 100%

  &__preview
    display: flex
    gap: 40px
    position: relative
    height: 100%

    @media (max-width: $md)
      flex-direction: column

    > .project-card
      flex-grow: 1
      width: 100%

  &__main
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-column-gap: 40px
    grid-row-gap: 30px
    flex-grow: 1
    max-height: 0
    overflow: hidden
    transition-duration: 1s

    @media (max-width: $md)
      display: flex
      flex-direction: column

  .projects-board__main ~ .projects-board__preview
    background-color: red

  &__section-name
    text-transform: uppercase 
    font-size: 7rem
    font-family: 'Futura PT Demi'
    padding: 10px
    cursor: pointer
    transition-duration: .5s
    border: 1px solid
    border-color: rgba(125, 125, 125, 0)

    @media (max-width: $lg)
      font-size: 4.8rem

    @media (max-width: $md)
      font-size: 2rem
      flex-grow: 1
      text-align: center

.section-name
  &_active
    border-color: rgba(125, 125, 125, .5)
    color: $red

    @media (max-width: $md)
      border: 0
      color: white
      background-color: $red

.pagination__container
  position: absolute
  bottom: $view-padding - 1rem
  width: 100%
  overflow-x: scroll
  overflow-y: hidden
  scrollbar-width: none
  -ms-overflow-style: none 

  &::-webkit-scrollbar
    width: 0
    height: 0

  @media (max-width: $md) 
    position: fixed
    bottom: 0
    left: 0
    z-index: 9
    
.projects-board__pagination
  display: flex
  gap: 2rem
  transition-duration: .5s

  @media (max-width: $md)
    gap: 0
    background-color: #242424

  &_hidden
    opacity: 0

.portfolio__open-button
  display: flex
  justify-content: center
  align-items: center
  gap: 1rem
  transition-duration: .5s
  
  .caption
    font-size: 1.3rem

  .arrow-button
    transform: rotate(90deg)

  &_hidden
    opacity: 0

.project
  max-height: 80%
  overflow-y: auto  

  @media (max-width: $md)
    overflow-y: unset

.preview
  transition-duration: .5s
  height: 100%

  .portfolio__heading
    display: flex
    justify-content: space-between


